import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo, useEffect } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#FCFAF6',
      neutralLight3: '#EDE8DF',
      neutralDark3: '#5F5F5B',
      neutralDark2: '#363634',
      neutralDark1: '#000000',
      primaryDark: '#282826',
      primaryLight: '#ED1C33',
    },
  },
  fontFamily: {
    heading: "'Jost', sans-serif",
    paragraph: "'EB Garamond', serif",
  },
}

interface Props {
  children: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({
  children,
  pageContext,
}: Props) {
  useEffect(() => {
    const script = document.createElement('script')

    if (pageContext.type === 'events') {
      script.innerHTML = `
        fbq('trackSingleCustom', '${process.env.FACEBOOK_PIXEL_ID}', 'PageView');
      `
    } else {
      script.innerHTML = `
        fbq('trackSingleCustom', '${process.env.FACEBOOK_PIXEL_ID_OLD}', 'PageView');
      `
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [pageContext])

  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider disableDynamicMediaQueries>
        {children}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
